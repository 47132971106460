<template>
  <div class="col-lg-5 col-md-7">
    <div class="card shadow-lg bg-gradient-secondary border-0 mb-0">
      <div class="card-body px-lg-5 py-lg-5">
        <div class="d-flex mb-4 justify-content-center">
          <!-- <button
            @click="facebook_login_trigger"
            type="button"
            class="btn btn-facebook btn-icon"
          >
            <span class="btn-inner--icon"
              ><em class="fab fa-facebook"></em
            ></span>
            <span class="btn-inner--text">Facebook</span>
          </button>
          <button
            id="google_login"
            type="button"
            class="btn btn-google-plus btn-icon"
          >
            <span class="btn-inner--icon"><em class="fab fa-google"></em></span>
            <span class="btn-inner--text">Google</span>
          </button> -->
        </div>
        <form role="form">
          <div class="form-group mb-3">
            <div class="input-group input-group-merge input-group-alternative">
              <div class="input-group-prepend">
                <span class="input-group-text"
                  ><i class="fa fa-phone"></i
                ></span>
              </div>
              <input
                class="form-control"
                :placeholder="$t('phone-number')"
                v-model="username"
                type="email"
              />
            </div>
          </div>
          <div class="form-group">
            <div class="input-group input-group-merge input-group-alternative">
              <div class="input-group-prepend">
                <span class="input-group-text"
                  ><i class="ni ni-lock-circle-open"></i
                ></span>
              </div>
              <input
                class="form-control"
                :placeholder="$t('password')"
                type="password"
                v-model="password"
              />
            </div>
          </div>
          <div class="text-center">
            <button type="button" @click="login" class="btn btn-default my-4">
              {{$t('sign-in')}}
            </button>
          </div>
        </form>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-6">
        <router-link to="/forgot_password" class="text-dark"
          ><small>{{$t('forgot-password')}}</small></router-link
        >
      </div>
      <div class="col-6 text-right">
        <router-link to="/register" class="text-dark"
          ><small>{{$t('create-new-account')}}</small>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>

export default {

  data() {
    return {
      
      username: "",
      password: "",
    };
  },
  methods: {
    login: function () {
      this.$store.dispatch("login", {
        username: this.username,
        password: this.password,
      });
    },
  },
  created() {
  },
};
</script>

<style>
</style>